<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M19.7845 23.7002H12.126C12.3045 23.7002 12.4485 25.4557 12.4485 27.6182C12.4485 29.7822 12.305 31.5377 12.126 31.5377H19.7845C19.6065 31.5377 19.462 29.7822 19.462 27.6182C19.463 25.4557 19.6065 23.7002 19.7845 23.7002Z"
      fill="#BFBEBE"
    />
    <path
      d="M14.8075 23.7002H12.126C12.3045 23.7002 12.4485 25.4557 12.4485 27.6182C12.4485 29.7822 12.305 31.5377 12.126 31.5377H14.8075C14.986 31.5377 15.13 29.7822 15.13 27.6182C15.13 25.4557 14.986 23.7002 14.8075 23.7002Z"
      fill="#AEAEAE"
    />
    <path
      d="M31.892 23.6551C31.892 25.6786 30.367 27.3181 28.489 27.3181H3.42403C1.54403 27.3181 0.0195312 25.6786 0.0195312 23.6551V5.09507C0.0195312 3.07507 1.54403 1.43457 3.42403 1.43457H28.489C30.367 1.43457 31.892 3.07357 31.892 5.09507V23.6551Z"
      fill="#D1D2D2"
    />
    <path
      d="M6.46508 23.6551V5.09507C6.46508 3.07507 7.98958 1.43457 9.86908 1.43457H3.42208C1.54208 1.43457 0.0175781 3.07357 0.0175781 5.09507V23.6551C0.0175781 25.6786 1.54208 27.3181 3.42208 27.3181H9.86908C7.98958 27.3181 6.46508 25.6781 6.46508 23.6546"
      fill="#C6C5C5"
    />
    <path
      d="M31.892 18.4704C31.892 20.4904 30.367 22.1304 28.489 22.1304H3.42403C1.54403 22.1304 0.0195312 20.4919 0.0195312 18.4704V5.08145C0.0195312 3.05795 1.54403 1.41895 3.42403 1.41895H28.489C30.367 1.41895 31.892 3.05795 31.892 5.08145V18.4704Z"
      fill="#243438"
    />
    <path
      d="M0.0175781 17.5254H31.8921V23.2089H0.0175781V17.5254Z"
      fill="#243438"
    />
    <path
      d="M29.3754 3.12207H2.53491C2.31341 3.12207 2.12891 3.24457 2.12891 3.39207V20.8376C2.12891 20.9856 2.31341 21.1076 2.53491 21.1076H29.3754C29.4959 21.1076 29.5874 21.0821 29.6539 21.0371L29.6639 21.0271C29.6639 21.0271 29.6719 21.0211 29.6739 21.0156C29.7067 20.9985 29.7343 20.9731 29.7539 20.9418C29.7735 20.9105 29.7844 20.8745 29.7854 20.8376V9.74757V3.39557C29.7834 3.24807 29.5969 3.12557 29.3754 3.12557"
      fill="#58C5E8"
    />
    <path
      d="M16.3491 14.7796C20.8371 17.4686 25.2216 19.2996 29.7831 20.4071V3.39207C29.7831 3.24457 29.5966 3.12207 29.3751 3.12207H4.74512C7.27512 7.88957 11.6291 11.9521 16.3501 14.7796"
      fill="#28A6DE"
    />
    <path
      d="M16.3854 17.6347C10.9704 14.6852 5.54891 10.5997 2.12891 5.55469V20.8367C2.12891 20.9847 2.31341 21.1067 2.53491 21.1067H24.4414C21.7654 20.2442 19.0969 19.1137 16.3864 17.6367"
      fill="#28A6DE"
    />
    <path
      d="M21.8608 31.3631C21.8608 31.7141 21.6918 31.9996 21.4843 31.9996H10.4293C10.2198 31.9996 10.0508 31.7141 10.0508 31.3631C10.0508 31.0116 10.2198 30.7266 10.4293 30.7266H21.4843C21.6918 30.7251 21.8608 31.0111 21.8608 31.3631Z"
      fill="#D1D2D2"
    />
    <path
      d="M13.9688 31.3631C13.9688 31.0116 14.1378 30.7266 14.3453 30.7266H10.2828C10.0748 30.7266 9.90625 31.0116 9.90625 31.3631C9.90625 31.7141 10.0753 31.9996 10.2828 31.9996H14.3453C14.1378 31.9996 13.9688 31.7141 13.9688 31.3631Z"
      fill="#C6C5C5"
    />
    <path
      d="M17.1651 24.3077C17.1161 24.2607 17.0446 24.2472 16.9471 24.2752L16.3856 24.4187C16.2106 24.4662 16.1221 24.5817 16.1221 24.7677L16.1201 25.3682C16.1201 25.4552 16.1396 25.5197 16.1786 25.5567C16.1951 25.5726 16.2146 25.5849 16.2361 25.5929C16.2575 25.6009 16.2803 25.6044 16.3031 25.6032C16.3331 25.6032 16.3611 25.5967 16.3971 25.5897L16.9581 25.4467C17.1331 25.3997 17.2221 25.2837 17.2241 25.0952V24.4967C17.2226 24.4082 17.2031 24.3447 17.1651 24.3077ZM16.8671 24.9912C16.8671 25.0577 16.8361 25.0987 16.7726 25.1162L16.5736 25.1682C16.5627 25.1704 16.5517 25.1717 16.5406 25.1722C16.5243 25.1726 16.5083 25.1671 16.4956 25.1567C16.4826 25.1442 16.4741 25.1207 16.4766 25.0867V24.8732C16.4766 24.8067 16.5076 24.7657 16.5701 24.7487L16.7716 24.6967C16.8061 24.6887 16.8321 24.6927 16.8471 24.7082C16.8621 24.7217 16.8686 24.7442 16.8686 24.7762V24.9917H16.8671V24.9912Z"
      fill="#243438"
    />
    <path
      d="M15.7905 24.7654C15.7905 24.5794 15.703 24.4634 15.5265 24.4164L14.9655 24.2729C14.8685 24.2449 14.7965 24.2599 14.75 24.3054C14.709 24.3424 14.6895 24.4059 14.6895 24.4939L14.6915 25.0914C14.6935 25.2799 14.781 25.3964 14.9555 25.4429L15.5185 25.5864C15.5515 25.5964 15.5825 25.5999 15.6095 25.5999C15.6323 25.6012 15.6552 25.5976 15.6767 25.5895C15.6981 25.5814 15.7176 25.569 15.734 25.5529C15.773 25.5164 15.7925 25.4524 15.7925 25.3644L15.7905 24.7654ZM15.4175 25.1544C15.4075 25.1649 15.3925 25.1699 15.373 25.1699C15.373 25.1699 15.3515 25.1679 15.34 25.1659L15.14 25.1139C15.078 25.0964 15.045 25.0554 15.045 24.9889V24.7734C15.045 24.7424 15.053 24.7194 15.066 24.7054C15.0815 24.6899 15.107 24.6859 15.1435 24.6939L15.3435 24.7459C15.4055 24.7629 15.4385 24.8039 15.4385 24.8704V25.0859C15.4385 25.1169 15.4385 25.1404 15.4175 25.1544Z"
      fill="#243438"
    />
    <path
      d="M16.1847 26.3046C16.1947 26.3046 16.2042 26.3026 16.2177 26.2981L16.4177 26.2466C16.4797 26.2321 16.5127 26.1891 16.5127 26.1206V25.9076C16.5135 25.8832 16.5067 25.8591 16.4932 25.8386C16.4757 25.8216 16.4482 25.8196 16.4157 25.8281L16.2137 25.8796C16.1517 25.8951 16.1202 25.9376 16.1202 26.0056L16.1182 26.2201C16.1182 26.2511 16.1262 26.2721 16.1397 26.2856C16.1497 26.2986 16.1652 26.3046 16.1847 26.3046Z"
      fill="#243438"
    />
    <path
      d="M15.7289 26.3046C15.717 26.3045 15.7051 26.3023 15.6939 26.2981L15.4939 26.2466C15.4319 26.2321 15.4004 26.1891 15.4004 26.1206L15.3984 25.9076C15.3984 25.8761 15.4064 25.8551 15.4199 25.8386C15.4354 25.8216 15.4624 25.8196 15.4954 25.8281L15.6994 25.8796C15.7599 25.8951 15.7929 25.9376 15.7929 26.0056V26.2201C15.7929 26.2511 15.7849 26.2721 15.7734 26.2856C15.7679 26.2919 15.761 26.2968 15.7533 26.3001C15.7456 26.3034 15.7373 26.3049 15.7289 26.3046Z"
      fill="#243438"
    />
  </svg>
</template>

<script>
export default {
  name: "PC",
};
</script>
